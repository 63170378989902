
import { defineComponent } from "vue-demi";
import StoreMain from "@/components/admin/store/index.vue";

export default defineComponent({
  name: "StoreView",
  components: {
    StoreMain,
  },
});
