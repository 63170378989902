import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4799cd34"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "store flex items-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_common_table = _resolveComponent("common-table")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_common_table, {
      class: "store--main",
      options: _ctx.options
    }, null, 8, ["options"]),
    _createVNode(_component_common_table, {
      class: "store--list",
      options: _ctx.options_two
    }, null, 8, ["options"])
  ]))
}